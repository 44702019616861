import React, {useContext, useEffect} from 'react';
import PropTypes from 'prop-types';

import analytics from 'analytics.js';
import {Button} from '@HealthShareAU/hs-component-library';
import {
    DirectoryStoreContext,
    ParamStoreContext,
} from 'core/stores/RootStore.js';

import query from 'query.js';
import {BOOK_NOW_BTN_BKG_PROD_PRIMARY} from 'core/constants';

export async function trackCustomBookNowButtonClick(objectType, objectId) {
    const data = {
        page: window.location.href,
        action: 'Click',
        object_type: objectType,
        object_id: objectId,
        pms_type: null,
    };
    return analytics.track('bookNowButtonActivity', data);
}

export async function trackCustomBookNowButtonImpression(
    objectType,
    objectId,
) {
    if (!window.trackedBookingEvent) {
        window.trackedBookingEvent = {};
    }
    if (window.trackedBookingEvent[`${objectType}-${objectId}`]) {
        return;
    }
    window.trackedBookingEvent[`${objectType}-${objectId}`] = true;
    const data = {
        page: window.location.href,
        action: 'impression',
        object_type: objectType,
        object_id: objectId,
        pms_type: null,
    };
    return analytics.track('bookNowButtonActivity', data);
}

function BookNowButton(props) {
    const {
        action,
        gaObjectType,
        gaSelector,
        objectType,
        objectId,
        params,
        text,
    } = props;
    const paramStore = useContext(ParamStoreContext);
    const directoryStore = useContext(DirectoryStoreContext);

    const generateBookingId = () => {
        const randomString = Math.random().toString(36).slice(2, 7);
        return Date.now().toString() + randomString;
    };

    const getBookingURL = () => {
        return document.body.dataset.bookingsUrl;
    };

    const shouldHideButton = () => {
        return paramStore.isReferrals || paramStore.hasSkin;
    };

    useEffect(() => {
        if (shouldHideButton()) {
            return;
        }
        // Prevent double tracking due to mounting of component multiple times
        if (
            !window.trackedBookingEvent ||
            !window.trackedBookingEvent[`${objectType}-${objectId}`]
        ) {
            trackBookNowButtonImpression();
        }
    }, []);

    const trackBookNowButtonClick = async (bookingsId) => {
        const data = {
            page: window.location.href,
            action: 'Click',
            object_type: objectType,
            object_id: objectId,
            'bookings_id': bookingsId,
            // Todo change Cliniko to other PMS types once we have integrated with other PMS
            pms_type: action ? null : 'Cliniko',
        };
        if (typeof directoryStore?.practitionerListEventId !== 'undefined') {
            await directoryStore.practitionerListEventTracked;
            data.practitioner_list_event_id =
                directoryStore.practitionerListEventId;
        }
        return analytics.track('bookNowButtonActivity', data);
    };

    const trackBookNowButtonImpression = async () => {
        if (!window.trackedBookingEvent) {
            window.trackedBookingEvent = {};
        }
        window.trackedBookingEvent[`${objectType}-${objectId}`] = true;
        const page = window.location.href;
        const data = {
            page,
            action: 'Impression',
            object_type: objectType,
            object_id: objectId,
            // Todo change Cliniko to other PMS types once we have integrated with other PMS
            pms_type: action ? null : 'Cliniko',
        };
        window.directoryStore = directoryStore;
        if (typeof directoryStore?.practitionerListEventId !== 'undefined') {
            await directoryStore.practitionerListEventTracked;
            data.practitioner_list_event_id =
                directoryStore.practitionerListEventId;
        }
        return analytics.track('bookNowButtonActivity', data);
    };

    const initiateBookingsWorkflow = async (params) => {
        const bookingsId = generateBookingId();
        await trackBookNowButtonClick(bookingsId);
        params.session_id = bookingsId;
        params.prev = window.location.href;
        return window.location.assign(
            query.buildQueryString(params, {
                fullPath: true,
                domain: getBookingURL(),
            }),
        );
    };
    return shouldHideButton() ? null : (
        <Button
            customClass="cta positive book-now"
            gaObjectType={gaObjectType}
            gaSelector={
                gaSelector ? gaSelector : BOOK_NOW_BTN_BKG_PROD_PRIMARY
            }
            iconLeft="date"
            onClick={
                action
                    ? () => {
                          trackBookNowButtonClick();
                          action();
                      }
                    : () => initiateBookingsWorkflow(params)
            }
            text={text ? text : 'Book Now'}
            dataTestId="book-now-button"
        />
    );
}

BookNowButton.propTypes = {
    params: PropTypes.shape({
        'practice_group':
            PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
        'practice_location':
            PropTypes.oneOfType[(PropTypes.string, PropTypes.number)],
    }).isRequired,
};
export default BookNowButton;
