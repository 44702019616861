import React from 'react';
import PropTypes from 'prop-types';

import Tooltip from 'core/components/Tooltip.js';

function Chips({labels, customClass = ''}) {
    return (
        <ul className={`chips ${customClass}`.trim()} data-testid="pill-chips">
            {labels.map((label, index) => {
                return (
                    <Tooltip
                        content={label.tooltip}
                        customClass={
                            label.customClass ||
                            label?.name?.toLowerCase() ||
                            ''
                        }
                        customTag="li"
                        defaultPosition="top"
                        key={`chip-${index}-${
                            label.customClass || label.name
                        }`}
                    >
                        {label.name}
                    </Tooltip>
                );
            })}
        </ul>
    );
}

Chips.propTypes = {
    customClass: PropTypes.string,
    labels: PropTypes.arrayOf(
        PropTypes.shape({
            customClass: PropTypes.string,
            icon: PropTypes.string,
            name: PropTypes.string.isRequired,
            tooltip: PropTypes.string.isRequired,
        }),
    ).isRequired,
};

export default Chips;
